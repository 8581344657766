import React, { useLayoutEffect, useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import axios from "axios"
import { graphql, Link } from "gatsby"
import { navigate } from "gatsby"
import { loadStripe } from '@stripe/stripe-js'
import { useShoppingCart } from 'use-shopping-cart'
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import { InlineWidget } from "react-calendly"
import {
  Article,
  ArticleHeader,
  ArticleSection
} from "../styledComponents/article"
import SignupIdentityForm from "../components/formIdentitySignup"
import SendGiftTo from "../components/formSendGiftTo";
import { StyledButton } from "../styledComponents/button"
import LoginRegisterBox from "../components/LoginRegisterBox"
import { analyticPurchaseEvent } from "../components/googleAnalytics"
import LayoutNoStripe from "../components/layoutNoStripe"

const Delayed = ({ children, waitBeforeShow = 3000 }) => {
  const [isShown, setIsShown] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsShown(true);
    }, waitBeforeShow);
  }, [waitBeforeShow]);
  return isShown ? children : null;
}

const stripe = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const PaymentSuccess = ({ data, location }) => {
  const {
    clearCart
  } = useShoppingCart()
  const [customer , setCustomer] = useState({})
  const [session , setSession] = useState({})
  const [user , setUser] = useState({})
  const [products , setProducts] = useState([])
  const [roles , setRoles] = useState([])
  const [signingUp, setSigningUp] = useState(false)
  const { register, handleSubmit, errors, watch, setValue } = useForm()
  const [formProcessing, setFormProcessing] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formError, setFormError] = useState()
  const [userFullName, setUserFullName] = useState()
  const [userPhoneNumber, setUserPhoneNumber] = useState()
  
  const identity = useIdentityContext()
  const [processing, setProcessing] = useState(false)  
  const updateRoles = ({ add, remove }) => {
    setProcessing(true)
    identity.authorizedFetch('/api/update-role', {
      method: 'POST',
      body: JSON.stringify({
        action: add ? 'add' : 'remove',
        role: add || remove
      })
    })
      .then(identity.refreshUser)
      .finally(() => setProcessing(false))
  }

  const getStripeSession = async () => {
    const urlParams = new URLSearchParams(window.location.search)
    const myParam = urlParams.get('sessionID') 
    if(myParam){
      fetch(`/.netlify/functions/stripe-customer?sessionID=${myParam}`)
      .then(response => response.json())
      .then((data) => {
        setSession(data.msg)
        setCustomer(data.customer)
        setProducts(data.productsList)
        let fullName = data.customer.name
        let firstName = fullName.split(' ').slice(0, -1).join(' ');
        let lastName = fullName.split(' ').slice(-1).join(' ');
        let email = data.customer.email
        let meta_data = {};
        meta_data['FIRSTNAME'] = capitalizeFirstLetter(firstName)
        meta_data['LASTNAME'] = capitalizeFirstLetter(lastName)
        let contents = ''
        data.productsList.map(prod => {
          contents += prod.name + ' - '          
        })
        
        const value = data.msg.amount_total
        const re = /\b(\d+)(\d{2})\b/
        const str = value
        const subst = '$1.$2'
        const totalPriceWithDecimal = str.toString().replace(re, subst)
        const currency = 'gbp'
        const categoryName = !data.productsList[0].metadata.type ? 'empty' : data.productsList[0].metadata.type
        analyticPurchaseEvent('purchase', categoryName, data.productsList[0].name, data.productsList[0].id, data.msg.id, data.msg.amount_total, 'GBP', '0', data.productsList[0].id, data.productsList[0].name )
        let interests
        if(products){          
          if(products[0]?.metadata?.type === 'permission-reset-anxiety'){
            interests = [
              {
                "id":"bbbcd5eceb",
                "name":"Permission to Reset Anxiety",
              }
            ]
            axios.post("/.netlify/functions/formHandler" , {
              firstName, 
              lastName,
              emailAddress:email.toLowerCase(), 
              interest: interests[0]
            })
            .then(data => {
              console.log('ERROR ',data)
            })
            .catch(console.log)
          }

          if(products[0]?.metadata?.type === 'permission-reset-sleep'){
            interests = [ 
              {
                "id":"bec5f83b5c",
                "name":"Permission to Reset Sleep",
              }
            ]
            axios.post("/.netlify/functions/formHandler" , {
              firstName, 
              lastName,
              emailAddress:email.toLowerCase(), 
              interest: interests[0]
            })
            .then(data => {
              console.log('ERROR ',data)
            })
            .catch(console.log)
          }
          if(products[0]?.metadata?.type === 'permission-reset-sleep-anxiety'){
            interests = [ 
              {
                "id":"caa01d0e71",
                "name":"Permission to Reset Sleep and Anxiety",
              }
            ]
            axios.post("/.netlify/functions/formHandler" , {
              firstName, 
              lastName,
              emailAddress:email.toLowerCase(), 
              interest: interests[0]
            })
            .then(data => {
              console.log('ERROR ',data)
            })
            .catch(console.log)
          }
        }
        
      })
      // const stripePromise = await loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)
    }
  }
  
  const onSubmit = async (data) => {
    setFormProcessing(true)
      await identity.update(
        {
          user_metadata: {
            roles: data.product
          }
        }
      )
      .then(_ => {
        updateRoles({ add: data.product })
        setFormProcessing(false)
        setFormSubmitted(true)
        navigate("/my-account")
        setTimeout(() => (setFormSubmitted(false)), 2000)
      })
      .catch(e => {
        setFormProcessing(false)
        setFormError(e.message)
        setTimeout(() => (setFormError()), 5000)
        return
    })
  }

  useLayoutEffect(() => {
    if (typeof window !== "undefined" || window.document) {
      getStripeSession()
      if(identity.user === undefined && roles.length === 0){
        setUser({...user,Signup: true})
      }else {
        setUser({...user,Signup: false})
      }
      if((identity.user && identity.user && !user.hasOwnProperty("id") ) || user.Signup)
      localStorage.removeItem('cart-values')
      return
    }
  }, [identity.user])

  return (
    <LayoutNoStripe displayNavBorder location={location}>
      <Article itemScope itemType="http://schema.org/Article">
        <ArticleHeader noMargin>
          <h1 itemProp="headline">Payment Success</h1>
        </ArticleHeader>
        <ArticleSection itemProp="articleBody">
          {products?.some(prod => prod.metadata.type === "consultation_distance-healing" ) ?
              <>
                <p>Thank you {customer && customer.name} for making a booking with Karina.</p>
                <p>Please select an available date &amp; time from Karina's calendar. If the dates or times are not suitable due to timezones or your schedule please get in contact with <a href="mailto:tracy@karinagrant.co.uk">Tracy</a>.</p>
                <InlineWidget 
                  pageSettings={{
                    backgroundColor: 'ffffff',
                    hideEventTypeDetails: true,
                    hideLandingPageDetails: true,
                    primaryColor: '9c27b0',
                    textColor: '242220'
                  }}
                  url="https://calendly.com/karinagranthealing/60min" 
                />
              </>
              // 
              : products?.some(prod => prod.metadata.type === "permission-reset-anxiety" ) ?
              <>
                <p>Thank you {customer && customer.name} for purchasing the Permission to Reset Anxiety™ program.</p>
                {!identity.user ? 
                <>
                  <LoginRegisterBox customer={customer} products={products} roles='permission-reset-anxiety'/>
                </>
                :
                <>
                <form onSubmit={handleSubmit(onSubmit)}>  
                  <input
                    value="permission-reset-anxiety"
                    {...register('product', { required: true })}
                    type="hidden"
                  />
                  <StyledButton style={{'marginLeft':'0'}} type="submit">Listen now</StyledButton>
                </form>
                  </>
                }
              </>
              : products?.some(prod => prod.metadata.type === "permission-reset-sleep" ) ?
              <>
                <p>Thank you {customer && customer.name} for purchasing the Permission to Reset™ Sleep program.</p>
                {!identity.user ? 
                <>
                  <LoginRegisterBox customer={customer} products={products} roles='permission-reset-sleep'/>
                </>
                :
                <>
                <form onSubmit={handleSubmit(onSubmit)}>  
                <input
                    value="permission-reset-sleep"
                    {...register('product', { required: true })}
                    type="hidden"
                  />
                  <StyledButton style={{'marginLeft':'0'}} type="submit">Listen now</StyledButton>
                </form>
                  </>
                }
              </>
              : products?.some(prod => prod.metadata.type === "permission-reset-sleep-anxiety" ) ?
              <>
                <p>Thank you {customer && customer.name} for purchasing the Permission to Reset™ Sleep &amp; Permission to Reset Anxiety programs.</p>
                {!identity.user ? 
                <>
                  <LoginRegisterBox customer={customer} products={products} roles='permission-reset-sleep-anxiety'/>
                </>
                :
                <>
                <form onSubmit={handleSubmit(onSubmit)}>  
                <input
                    value="permission-reset-sleep-anxiety"
                    {...register('product', { required: true })}
                    type="hidden"
                  />
                  <StyledButton style={{'marginLeft':'0'}} type="submit">Listen now</StyledButton>
                </form>
                  </>
                }
              </>
          : products?.some(prod => prod.metadata.type === "shm-program" ) ?
              <>
                <p>Thank you {customer && customer.name} for purchasing the Permission to Self Healing Mastery program.</p>
                {!identity.user ? 
                <>
                  <LoginRegisterBox customer={customer} products={products} roles='shm'/>
                </>
                :
                <form onSubmit={handleSubmit(onSubmit)}>  
                <input
                    value="shm"
                    {...register('product', { required: true })}
                    type="hidden"
                  />
                  <StyledButton style={{'marginLeft':'0'}} type="submit">Listen now</StyledButton>
                </form>
                }
              </>
          : products?.some(prod => prod.metadata.type === "spoon-therapy" ) ?
          <>
            <p>Thank you {customer && customer.name} for purchasing the Spoon Therapy program.</p>
            {!identity.user ? 
            <>
              <LoginRegisterBox customer={customer} products={products} roles='spoon-therapy'/>
            </>
            :
            <form onSubmit={handleSubmit(onSubmit)}>  
            <input
                value="spoon-therapy"
                {...register('product', { required: true })}
                type="hidden"
              />
              <StyledButton style={{'marginLeft':'0'}} type="submit">Watch now</StyledButton>
            </form>
            }
          </>
          : products?.some(prod => prod.metadata.type === "gift-distance-healing" ) ?
              <>
                <p>Thank you {customer && customer.name} for gifting someone a <strong>Distance healing session</strong> to someone you love.</p>
                <p>Please enter the name and email address of the person you want to gift this to and we will send them an email on how to book.</p>
                <SendGiftTo customer={customer} products={products}/>
              </>
          : products?.some(prod => prod.metadata.type === "gift-qt-level-1" ) ?
              <>
                <p>Thank you {customer && customer.name} for gifting a <strong>Quantum-Touch Level 1</strong> workshop.</p>
                <p>Please enter the name and email address of the person you want to gift this to and we will send them an email on how to book.</p>
                <SendGiftTo customer={customer} products={products}/>
              </>
          : products?.some(prod => prod.metadata.type === "gift-chakra-balancing-collection" ) ?
              <>
                <p>Thank you {customer && customer.name} for gifting the <strong>Chakra Balancing Collection</strong> to someone you love.</p>
                <p>Please enter the name and email address of the person you want to gift this collection to, and we will send them an email with the information on how to login and listen.</p>
                <SendGiftTo customer={customer} products={products}/>
              </>
          : products?.some(prod => prod.metadata.type === "gift-mindfulness-meditation" ) ?
              <>
                <p>Thank you {customer && customer.name} for gifting <strong>The Essential Mindfulness Collection</strong> to someone you love.</p>
                <p>Please enter the name and email address of the person you want to gift this collection to, and we will send them an email with the information on how to login and listen.</p>
                <SendGiftTo customer={customer} products={products}/>
              </>
          : products?.some(prod => prod.metadata.type === "lightspace" ) ?
              <>
                <p>Thank you {customer && customer.name} for registering for the Quantum-Touch LightSpace session with Karina.</p>
                <p>Please select from the calendar the date you would like to join the collective Quantum-Touch LightSpace</p>
                <InlineWidget 
                  pageSettings={{
                    backgroundColor: 'ffffff',
                    hideEventTypeDetails: true,
                    hideLandingPageDetails: true,
                    primaryColor: '9c27b0',
                    textColor: '242220'
                  }}
                  url="https://calendly.com/karinagranthealing/collective" 
                />
              </>
          : products?.some(prod => prod.metadata.type === "lightspace-bundle" ) ?
              <>
                <p>Thank you {customer && customer.name} for registering for the Quantum-Touch LightSpace sessions with Karina.</p>
                <p>Please select from the calendar the date you would like to join the collective Quantum-Touch LightSpace</p>
                <InlineWidget 
                  pageSettings={{
                    backgroundColor: 'ffffff',
                    hideEventTypeDetails: true,
                    hideLandingPageDetails: true,
                    primaryColor: '9c27b0',
                    textColor: '242220'
                  }}
                  url="https://calendly.com/karinagranthealing/collective" 
                />
              </>
          : products?.some(prod => prod.metadata.type === "event") ? 
              <>
                <p>Thank you {customer && customer.name} for booking onto the <strong>{products[0].name}</strong> workshop with Karina.</p>
                <p>The workshop registration details has been sent to your email address <strong>{customer.email}</strong>.</p>
                <p>Please check your email if you do not recieve something from tracy@karinagrant.co.uk within 24 hours please get in <Link to="/contact">contact</Link>.</p>
                
              </>
          : products?.some(prod => prod.metadata.type === "audio") ? 
              <>
              <p>Thank you for your buying:</p>
              <ul>
                {products.map((item, index) => ( 
                  <li key={item.id}>
                      {item.name}
                  </li>
                ))}
              </ul>
              <p>All of Karina's audios are streamed online.</p>
              {!identity.user ? 
                <>
                  <LoginRegisterBox customer={customer} products={products} roles='shm'/>
                </>
                :
                <form onSubmit={handleSubmit(onSubmit)}>  
                <input
                    value={products[0].metadata.role}
                    {...register('product', { required: true })}
                    type="hidden"
                  />
                  <StyledButton style={{'marginLeft':'0'}} type="submit">Listen now</StyledButton>
                </form>
                }
              </>
          :
              <>
              <Delayed>
              <p>Thank you for your order, please check your email, if you do not recieve anything please get in <Link to="/contact">contact</Link> and we will take a look at your order.</p>
              </Delayed>
              </>
          }
        </ArticleSection>
      </Article>
    </LayoutNoStripe>
  )
}

export default PaymentSuccess

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
